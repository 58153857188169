import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f126eafd34e405d49ef540347feffc64@o190059.ingest.us.sentry.io/4507736330207232",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "55534d0e0b1c9acc8f570cb5a0e3328966136009",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});